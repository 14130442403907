import React, { ReactNode } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject
} from "@apollo/client";
import { useAuth } from "./AuthProvider";
import { createUploadLink } from "apollo-upload-client";
import { mergePaginatedResults } from "./helpers/apollo";

// import { relayStylePagination } from "@apollo/client/utilities";

interface PlatformApolloProviderProps {
  children: ReactNode;
}

export const PlatformApolloProvider = ({
  children
}: PlatformApolloProviderProps) => {
  const { apiFetch } = useAuth();

  // Including auth via Kong and the Ext Users app
  // TS bug waiting for fix. See:
  // https://github.com/jaydenseric/apollo-upload-client/issues/221
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
  const link = (createUploadLink({
    uri: "/graphql",
    fetch: apiFetch
  }) as unknown) as ApolloLink;

  // Bypassing auth for local dev
  // const link = createHttpLink({
  //   uri: "http://localhost/graphql",
  //   headers: {
  //     "X-CONSUMER-ID": "f51e4c8f-324b-3491-a03f-4e2fba1eb07e"
  //   }
  // });

  // Typing recommended from https://www.apollographql.com/docs/tutorial/client/#initialize-apolloclient
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache: new InMemoryCache({
      // pagination helpers: https://www.apollographql.com/blog/announcing-the-release-of-apollo-client-3-0/
      typePolicies: {
        Query: {
          fields: {
            // Merge paginated queries for customers into one array.
            // Note this pattern doesn't work for nested stuff like products in price books though.
            customers: {
              keyArgs: ["orderBy", "where"],
              merge: mergePaginatedResults
            }
          }
        },
        // Merge on type field so it works in nested query
        PriceBook: {
          fields: {
            products: {
              keyArgs: ["orderBy", "where"],
              merge: mergePaginatedResults
            }
          }
        }
      }
    }),
    link,
    connectToDevTools: true
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
