import React from "react";
import Main from "../../layout/Main";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer
} from "../../components/LayoutContainers";
import { CircularProgress } from "@material-ui/core";
import SearchTabs from "./components/SearchTabs";
import { search } from "../../api/globalSearch";
import { useQuery } from "react-query";
import { Alert } from "@akj-dev/design-system";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { Helmet } from "react-helmet";
import { useBrand } from "@akj-dev/affinity-platform";
import { SubNav } from "../../nav/SubNav";
import { getRoutes } from "../../routes";

export default function GlobalSearchScreen() {
  const brand = useBrand();
  const routes = getRoutes();
  const [query] = useQueryParam("query", withDefault(StringParam, ""));

  const searchResult = useQuery(["search", query], () =>
    search({ search: query })
  );

  const getContent = () => {
    const { error, isLoading, isFetchedAfterMount } = searchResult;
    if (error) {
      if (error instanceof Error) {
        return <Alert message={error.message} type="error" />;
      }
    }

    if (isLoading || !isFetchedAfterMount) {
      return <CircularProgress style={{ marginTop: 20 }} />;
    }

    return (
      <Main
        title={
          (searchResult.data?.meta?.totalCount || 0) +
          " results" +
          (query ? " for '" + query + "'" : "")
        }
      >
        <SearchTabs searchResult={searchResult} />
      </Main>
    );
  };

  return (
    <ProductContainer>
      <Helmet>
        <title>{brand.pageTitle} | Search</title>
      </Helmet>
      <SubNav routes={routes} rootTitle="Home" />
      <ViewContainer>
        <ContentContainer>{getContent()}</ContentContainer>
      </ViewContainer>
    </ProductContainer>
  );
}
