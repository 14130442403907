import React from "react";
import {
  Typography,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

/** A card to display simple text data */
type DataCardData = {
  title: string;
  value: string | number;
};
export interface DataCardProps {
  "data-cy"?: string;
  title: string;
  data: string | number | React.ReactElement | DataCardData[];
  callToAction?: string;
  href?: string;
}

export const DataCard = ({
  "data-cy": dataCy,
  title,
  data,
  callToAction,
  href
}: DataCardProps) => {
  const useStyles = makeStyles(theme => ({
    root: {
      "&:hover $actions": {
        backgroundColor: theme.palette.primary.dark
      }
    },
    header: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    content: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      display: "flex",
      justifyContent: "center"
    },
    actions: {
      transition: `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
    },
    item: {
      cursor: "pointer",
      fontWeight: 600,
      fontSize: 24,
      paddingRight: theme.spacing(0.25),
      paddingLeft: theme.spacing(0.25),
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textDecoration: "none",
      textAlign: "center",
      color: theme.palette.text.primary
    }
  }));
  const classes = useStyles();

  let dataDisplay = <div />;

  if (typeof data === "string" || typeof data === "number") {
    dataDisplay = (
      <Typography variant="h1" component="div" align="center">
        {data}
      </Typography>
    );
  } else if (Array.isArray(data)) {
    dataDisplay = (
      <>
        {data.map(d => (
          <Box className={classes.item}>
            <Box>{d.value}</Box>
            <Box fontSize={12}>{d.title}</Box>
          </Box>
        ))}
      </>
    );
  } else if (React.isValidElement(data)) {
    dataDisplay = data;
  }

  return (
    <a href={href} style={{ textDecoration: "none" }} data-cy={dataCy}>
      <Card raised className={classes.root}>
        <CardHeader
          titleTypographyProps={{ align: "center" }}
          className={classes.header}
          title={title}
        />
        <CardContent className={classes.content}>{dataDisplay}</CardContent>
        {!!callToAction && (
          <CardActions className={classes.actions}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <div>{callToAction}</div>
              <ChevronRight fontSize="small" />
            </Box>
          </CardActions>
        )}
      </Card>
    </a>
  );
};
