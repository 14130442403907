import React from "react";
import { ReactComponent as HomeIcon } from "../images/home.svg";
import {
  AffinitySalesIcon,
  AffinityOrderIcon,
  AffinityInsightIcon,
  AffinityBillingIcon,
  AffinitySupportIcon,
  AffinityHubIcon,
  AffinityCustomersIcon
} from "@akj-dev/design-system";
import { affinityColors as colors } from "@akj-dev/design-system";
import { AccountSettings } from "@akj-dev/affinity-platform";

// Wrapping SVG to suppress dom property warning
const HomeIconWrap = () => <HomeIcon />;

type PlatformMenuItemFlash = {
  primary: string;
  secondary: string;
};

type PlatformMenuItem = {
  icon: React.ReactNode;
  label: string;
  link: string;
  flash?: PlatformMenuItemFlash;
  permissions?: (keyof AccountSettings)[];
};

export const platformMenuItems: PlatformMenuItem[] = [
  {
    icon: HomeIconWrap,
    label: "Home",
    link: "/"
  },
  {
    icon: AffinityCustomersIcon,
    label: "Customers",
    link: "/customers",
    permissions: ["can_use_affinity_customers"]
  },
  {
    icon: AffinitySalesIcon,
    label: "Sales",
    link: "/sales",
    flash: {
      primary: colors.affinityBlueDark.value,
      secondary: colors.affinityBlueLight.value
    },
    permissions: ["can_use_affinity_sales"]
  },
  {
    icon: AffinityOrderIcon,
    label: "Orders",
    link: "/orders",
    flash: {
      primary: colors.affinityTealDark.value,
      secondary: colors.affinityTealLight.value
    },
    permissions: ["can_use_affinity_orders"]
  },
  {
    icon: AffinityBillingIcon,
    label: "Billing",
    link: "/billing",
    flash: {
      primary: colors.affinityGreenDarker.value,
      secondary: colors.affinityGreenLighter.value
    },
    permissions: ["can_use_affinity_billing"]
  },
  {
    icon: AffinityInsightIcon,
    label: "Insight",
    link: "/insight",
    flash: {
      primary: colors.affinityVioletDark.value,
      secondary: colors.affinityVioletLight.value
    },
    permissions: ["can_use_affinity_insight"]
  },
  {
    icon: AffinityHubIcon,
    label: "Hub",
    link: "/hub",
    flash: {
      primary: colors.affinityPinkDarker.value,
      secondary: colors.affinityPinkLighter.value
    },
    permissions: ["can_use_affinity_hub"]
  },
  {
    icon: AffinitySupportIcon,
    label: "Support",
    link: "/support",
    flash: {
      primary: colors.affinityOrangeDark.value,
      secondary: colors.affinityOrangeLight.value
    },
    permissions: ["can_use_affinity_support", "can_use_affinity_support_new"]
  }
];
