import { ReactElement } from "react";
import { matchPath } from "react-router-dom";

export interface RoutingElement {
  path: string;
  title: string;
  subject: ReactElement | RoutingTable;
  icon?: object;
}

export type RoutingTable = Array<RoutingElement>;

export type RoutingObject = {
  rootPath: string;
  hierarchy: RoutingTable;
  flat: Array<RoutingElement>;
};

export function createRoutingObject(
  routes: RoutingTable,
  rootPath: string
): RoutingObject {
  let expandedRoutes = removeTrailingSlash(expandPaths(routes, rootPath));
  setParent(expandedRoutes, {});
  return {
    rootPath,
    hierarchy: expandedRoutes,
    flat: flattenRoutes(expandedRoutes)
  };
}

function expandPaths(routes: RoutingTable, rootPath: string): RoutingTable {
  return routes.map(el => {
    const expandedPath = `${rootPath}${el.path}`;
    return {
      ...el,
      path: expandedPath,
      subject: Array.isArray(el.subject)
        ? expandPaths(el.subject, expandedPath)
        : el.subject
    };
  });
}

function removeTrailingSlash(routes: RoutingTable) {
  for (const r of routes) {
    r.path = r.path.replace(/\/$/, "");
  }
  return routes;
}

function setParent(o: any, parent: any) {
  for (let r of o) {
    r.parent = parent;
    if (Array.isArray(r.subject)) setParent(r.subject, r);
  }
}

function flattenRoutes(routes: RoutingTable) {
  let flatRoutes: Array<RoutingElement> = [];

  const flatten = (routes: RoutingTable) => {
    routes.forEach(route => {
      if (Array.isArray(route.subject)) {
        flatten(route.subject);
      } else {
        flatRoutes.push(route);
      }
    });
  };

  flatten(routes);

  flatRoutes.sort((a, b) => {
    // Find specificity of paths, by how many "/" they have
    const sA = a.path.split("/").length;
    const sB = b.path.split("/").length;
    if (sA > sB) return -1;
    if (sA < sB) return 1;
    return 0;
  });

  return flatRoutes;
}

export function findCurrentRoute(current: string, routes: RoutingTable): any {
  // console.log("findCurrentRoute", current, routes);
  for (let r of routes) {
    // console.log("fc:", r);
    if (
      matchPath(current, {
        // @ts-ignore
        path: r.path,
        exact: true,
        strict: false
      })
    ) {
      // console.log('PATH MATCH', current);
      return r;
    }
    // @ts-ignore
    if (Array.isArray(r.subject)) {
      const f = findCurrentRoute(current, r.subject);
      if (f) return f;
    }
  }
}

export function getCurrentPlatformItemIndex(
  items: Array<any>,
  pathname: string
) {
  return pathname === "/"
    ? 0
    : items.findIndex(
        item => item.link !== "/" && pathname.startsWith(item.link)
      );
}
