import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import queryString from "query-string";
import {
  AuthProvider,
  PlatformApolloProvider,
  SnackbarProvider,
  useBrand
} from "@akj-dev/affinity-platform";
import { MUIProviders } from "./MUIProviders";
import { UATBanner } from "./components/UATBanner";
import config from "./config";
import Cookies from "js-cookie";
import "./AppStandalone.css";
import { ErrorFullScreen, LoadingFullScreen } from "@akj-dev/design-system";
import { LoginScreen } from "./auth/LoginScreen";
import { AppRoutes } from "./AppRoutes";
import { PopulateGTMDataLayer } from "./components/PopulateGTMDataLayer";
import { Helmet } from "react-helmet";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

const queryCache = new QueryCache();

const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      staleTime: 0
    }
  }
});

export function App() {
  const brand = useBrand();
  const stage = process.env.REACT_APP_STAGE;

  // If in development or uat, respect any existing auth API base URL in cookie
  const [authAPIBaseUrl, setAuthAPIBaseUrl] = useState(
    (stage !== "production" && Cookies.get("ap_uatBannerAuthApi")) ||
      config.authAPIBaseUrl[process.env.REACT_APP_STAGE]
  );

  const query = queryString.parse(window.location.search);

  return (
    <QueryClientProvider client={queryClient}>
      <MUIProviders>
        <Helmet>
          <link rel="shortcut icon" href={brand.favicon} />
        </Helmet>
        <SnackbarProvider>
          <AuthProvider
            authApiBaseUrl={authAPIBaseUrl}
            loginToken={(query.login_token || "") as string}
            initScreen={() => (
              <LoadingFullScreen message="Loading user session..." />
            )}
            loginScreen={(handleLogin, loading, error) => (
              <>
                {(stage === "uat" || stage === "development") && (
                  <UATBanner
                    authAPIBaseUrl={authAPIBaseUrl}
                    setAuthAPIBaseUrl={setAuthAPIBaseUrl}
                  />
                )}
                <LoginScreen
                  handleLogin={handleLogin}
                  loading={loading}
                  error={error}
                />
              </>
            )}
            errorScreen={(message, retry) => {
              return (
                <ErrorFullScreen
                  message={`Authentication error: ${message}`}
                  retry={retry}
                />
              );
            }}
          >
            {(stage === "uat" || stage === "development") && (
              <UATBanner
                authAPIBaseUrl={authAPIBaseUrl}
                setAuthAPIBaseUrl={setAuthAPIBaseUrl}
              />
            )}
            <PlatformApolloProvider>
              <PopulateGTMDataLayer />
              <Router>
                <AppRoutes />
              </Router>
            </PlatformApolloProvider>
          </AuthProvider>
        </SnackbarProvider>
      </MUIProviders>
    </QueryClientProvider>
  );
}
