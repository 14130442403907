import React, { useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ReceiptIcon from "@material-ui/icons/Receipt";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import CustomersTable from "./CustomersTable";
import BillingTable from "./BillingTable";
import OrdersTable from "./OrdersTable";
import SupportTable from "./SupportTable";
import SalesTable from "./SalesTable";
import TabPanel from "./TabPanel";
import TabLabel from "./TabLabel";
import { MUIDataTableOptions } from "mui-datatables";

interface SearchTabsProps {
  searchResult?: any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function SearchTabs(props: SearchTabsProps) {
  const indicatorColors = [
    "#271D3E", // Customers
    "#56B9F1", // Sales
    "#70D0C0", // Orders
    "#84E396", // Billing
    "#FFD183" // Support
  ];
  const [value, setValue] = useState(0);
  const [indicatorColor, setIndicatorColor] = useState(indicatorColors[0]);

  const { data } = props.searchResult;

  const handleChange = (e: any, newValue: number) => {
    setValue(newValue);
    setIndicatorColor(indicatorColors[newValue]);
  };

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,
    disableToolbarSelect: true
  };

  return (
    <Box width="100%" marginBottom={10} marginLeft="auto" marginRight="auto">
      <Box borderBottom={2} borderColor="divider">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ marginBottom: 10 }}
          TabIndicatorProps={{
            style: { height: 4, backgroundColor: indicatorColor }
          }}
        >
          <Tab
            disableTouchRipple
            icon={
              <PeopleOutlineIcon
                fontSize="large"
                style={{ color: indicatorColors[0] }}
              />
            }
            label={
              <TabLabel
                label="Customers"
                numResults={data.customers?.meta?.totalCount || 0}
              />
            }
            {...a11yProps(0)}
            style={{ minWidth: "20%" }}
          />
          <Tab
            disableTouchRipple
            icon={
              <TrendingUpIcon
                fontSize="large"
                style={{ color: indicatorColors[1] }}
              />
            }
            label={
              <TabLabel
                label="Sales"
                numResults={data.sales?.meta?.totalCount || 0}
              />
            }
            {...a11yProps(1)}
            style={{ minWidth: "20%" }}
          />
          <Tab
            disableTouchRipple
            icon={
              <ReceiptIcon
                fontSize="large"
                style={{ color: indicatorColors[2] }}
              />
            }
            label={
              <TabLabel
                label="Order"
                numResults={data.orders?.meta?.totalCount || 0}
              />
            }
            {...a11yProps(2)}
            style={{ minWidth: "20%" }}
          />
          <Tab
            disableTouchRipple
            icon={
              <DescriptionIcon
                fontSize="large"
                style={{ color: indicatorColors[3] }}
              />
            }
            label={
              <TabLabel
                label="Billing"
                numResults={data.billing?.meta?.totalCount || 0}
              />
            }
            {...a11yProps(3)}
            style={{ minWidth: "20%" }}
          />
          <Tab
            disableTouchRipple
            icon={
              <HelpOutlineIcon
                fontSize="large"
                style={{ color: indicatorColors[4] }}
              />
            }
            label={
              <TabLabel
                label="Support"
                numResults={data.support?.meta?.totalCount || 0}
              />
            }
            {...a11yProps(4)}
            style={{ minWidth: "20%" }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <CustomersTable
            results={data.customers?.results || []}
            meta={data.customers?.meta || {}}
            options={options}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SalesTable
            results={data.sales?.results || []}
            meta={data.sales?.meta || {}}
            options={options}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OrdersTable
            results={data.orders?.results || []}
            meta={data.orders?.meta || {}}
            options={options}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BillingTable
            results={data.billing?.results || []}
            meta={data.billing?.meta || {}}
            options={options}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <SupportTable
            results={data.support?.results || []}
            meta={data.support?.meta || {}}
            options={options}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
