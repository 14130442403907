import React from "react";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions
} from "mui-datatables";
import { Box, Typography } from "@material-ui/core";
import { formattedTimestamp } from "../../../helpers/functions";

type Props = {
  meta: {};
  results: {}[];
  options: MUIDataTableOptions;
};

export default function OrdersTable({ meta, results, options }: Props) {
  const columns: MUIDataTableColumn[] = [
    {
      name: "orderId",
      label: "Order"
    },
    {
      name: "accountName",
      label: "Company Name"
    },
    {
      name: "status",
      label: "Status"
    },
    {
      name: "createdAtTimestamp",
      label: "Created",
      options: {
        customBodyRender: (value: number) => {
          return formattedTimestamp(value);
        }
      }
    },
    {
      name: "submittedAtTimestamp",
      label: "Submitted",
      options: {
        customBodyRender: (value: number) => {
          return formattedTimestamp(value);
        }
      }
    }
  ];

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Results</Typography>
          </Box>
        </Box>
      }
      data={results}
      columns={columns}
      options={options}
    />
  );
}
