import React, { useEffect } from "react";
import { useAuth } from "@akj-dev/affinity-platform";

/**
 * Expose account settings on the window object.
 * This is so they can be picked up by GTM / Intercom etc to track logged in users.
 *
 * @constructor
 */
export const PopulateGTMDataLayer = () => {
  const { accountSettings, accountMeta, externalUserName } = useAuth();
  useEffect(() => {
    window.dataLayer.push({
      ...accountSettings,
      ...accountMeta,
      externalUserName,
      event: "accountMeta"
    });
  }, [accountSettings, accountMeta, externalUserName]);
  return <div />;
};
