import React from "react";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions
} from "mui-datatables";
import { Box, Typography } from "@material-ui/core";
import { formattedTimestamp } from "../../../helpers/functions";

type Props = {
  meta: [];
  results: {}[];
  options: MUIDataTableOptions;
};

export default function SalesTable({ meta, results, options }: Props) {
  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "ID"
    },
    {
      name: "opportunityName",
      label: "Opp Name"
    },
    {
      name: "companyName",
      label: "Company Name"
    },
    {
      name: "createdAtTimestamp",
      label: "Date & Time",
      options: {
        customBodyRender: (value: number) => {
          return formattedTimestamp(value);
        }
      }
    },
    {
      name: "createdByName",
      label: "Created By"
    }
  ];

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Results</Typography>
          </Box>
        </Box>
      }
      data={results}
      columns={columns}
      options={options}
    />
  );
}
