import React from "react";
import { activeThemeBrand, AppTheme } from "@akj-dev/affinity-platform";
import {
  AffinityLogomark,
  VodafoneLogo,
  DWSLogotypeLight
} from "@akj-dev/design-system";

export function SubNavLogo(props: React.SVGProps<SVGSVGElement>) {
  switch (activeThemeBrand()) {
    case AppTheme.VODAFONE:
      return <VodafoneLogo {...props} />;
    case AppTheme.DWS:
      return <DWSLogotypeLight />;
    case AppTheme.AFFINITY:
    default:
      return <AffinityLogomark {...props} />;
  }
}
