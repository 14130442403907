import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { affinityColors as colors } from "@akj-dev/design-system";
import { platformMenuItems as items } from "./platformMenuItems";
import { activeThemeBrand } from "@akj-dev/affinity-platform";
import { AppTheme, useAuth } from "@akj-dev/affinity-platform";
import { useTheme } from "@material-ui/core/styles";

const iconSize = 30;

const Outer = styled.div`
  z-index: 15;
  position: fixed;
  left: 0;
  width: 56px;
  padding-top: 4px;
  overflow: hidden;
  height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: ${p => p.theme.palette.grey[100]};
  transition: width 100ms ease-in 400ms, box-shadow 100ms ease-in 400ms;
  &:hover {
    width: 140px;
    box-shadow: 0 0 120px rgba(0, 0, 0, 0.5);
  }
  a {
    text-decoration: none;
  }
`;

const ListItem = styled.div`
  display: flex;
  padding: 4px 8px 4px 7px;
  align-items: center;
  width: 140px;
  color: black;
`;

const IconWrap = styled.div`
  margin-right: 10px;
  padding: 4px;
  width: ${iconSize + 12}px;
  height: ${iconSize + 12}px;
  border-radius: ${p => (p.round ? "100%" : "14px")};
  border: 2px solid transparent;
  transition: border-color 0.2s;
  &:hover {
    border-color: ${p => p.theme.palette.grey[200]};
  }
  &.active {
    border-color: ${p => p.activeBorderColor};
  }
`;

export function PlatformMenu() {
  const location = useLocation();
  const theme = useTheme();
  const { accountSettings } = useAuth();

  let bgColor, fgColor, round, activeBorderColor;

  switch (activeThemeBrand()) {
    case AppTheme.VODAFONE:
      bgColor = "transparent";
      fgColor = theme.palette.primary.main;
      round = true;
      activeBorderColor = theme.palette.primary.main;
      break;
    case AppTheme.DWS:
      bgColor = theme.palette.primary.main;
      fgColor = "white";
      round = false;
      activeBorderColor = theme.palette.secondary.main;
      break;
    case AppTheme.AFFINITY:
    default:
      bgColor = undefined;
      fgColor = undefined;
      round = false;
      activeBorderColor = colors.neutralStorm.value;
  }
  return (
    <Outer>
      {items
        .filter(item => {
          if (item.permissions?.length) {
            return (
              item.permissions.filter(permission => {
                return accountSettings[permission] === "1";
              }).length > 0
            );
          }

          return true;
        })
        .map((item, i) => {
          let className = location.pathname.startsWith(item.link)
            ? "active"
            : undefined;

          if (item.link === "/" && location.pathname !== "/")
            className = undefined;

          return (
            <Link to={item.link} key={i}>
              <ListItem>
                <IconWrap
                  className={className}
                  round={round}
                  activeBorderColor={activeBorderColor}
                >
                  <item.icon
                    size={iconSize}
                    bgcolor={bgColor}
                    fgcolor={fgColor}
                  />
                </IconWrap>
                {item.label}
              </ListItem>
            </Link>
          );
        })}
    </Outer>
  );
}
