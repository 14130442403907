import { platformApiFetch } from "@akj-dev/affinity-platform";
import queryString from "query-string";

class BaseError {
  constructor() {
    // @ts-ignore
    Error.apply(this, arguments);
  }
}

// BaseError.prototype = new Error();

export class AuthorizationError extends BaseError {
  constructor(public validation: any, public message: string) {
    super();
  }
}

export class ValidationError extends BaseError {
  constructor(public validation: any, public message: string) {
    super();
  }
}

function handleErrors(response: any) {
  if (!response.ok) {
    if (response.status === 403) {
      throw new AuthorizationError(response.json(), response.statusText);
    }

    if (response.status === 422) {
      throw new ValidationError(response.json(), response.statusText);
    }

    throw Error(response.statusText);
  }

  return response;
}

function json(response: any) {
  return response.json();
}

function getHeaders() {
  let headers = new Headers();

  headers.append(
    "Content-type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );
  headers.append("pragma", "no-cache");
  headers.append("cache-control", "no-cache");

  return headers;
}

export function get(url: string, params?: any, options?: any) {
  const query = params
    ? "?" +
      queryString.stringify(params, { arrayFormat: "bracket", skipNull: true })
    : "";

  return platformApiFetch("ExternalServices/v1/" + url + query, {
    ...options,
    headers: {
      ...getHeaders(),
      ...options?.headers
    }
  })
    .then(handleErrors)
    .then(json);
}
