import React from "react";
import { Typography } from "@material-ui/core";

interface TabLabelProps {
  label: string;
  numResults: number;
}

export default function TabLabel({ label, numResults }: TabLabelProps) {
  return (
    <>
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body2">{numResults} Results</Typography>
    </>
  );
}
