import React from "react";
import { Typography } from "@material-ui/core";
import Main from "../layout/Main";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer
} from "../components/LayoutContainers";
import { Helmet } from "react-helmet";
import { useBrand } from "@akj-dev/affinity-platform";

interface ForbiddenScreenProps {}

export const ForbiddenScreen: React.FC<ForbiddenScreenProps> = () => {
  const brand = useBrand();

  return (
    <ProductContainer>
      <Helmet>
        <title>{brand.pageTitle} | Forbidden</title>
      </Helmet>
      <ViewContainer>
        <ContentContainer>
          <Main title="Forbidden">
            <Typography>
              You don't have permissions to access this page.
            </Typography>
          </Main>
        </ContentContainer>
      </ViewContainer>
    </ProductContainer>
  );
};
