import React from "react";
import { Grid } from "@material-ui/core";
import HomeScreenCard from "../components/HomeScreenCard";
import Main from "../layout/Main";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer
} from "../components/LayoutContainers";
import { getRoutes } from "../routes";
import { SubNav } from "../nav/SubNav";
import { useAuth, useBrand } from "@akj-dev/affinity-platform";
import { Helmet } from "react-helmet";

interface HomeScreenProps {}

export const HomeScreen: React.FC<HomeScreenProps> = () => {
  const brand = useBrand();
  const routes = getRoutes();
  const { accountSettings } = useAuth();

  return (
    <ProductContainer>
      <Helmet>
        <title>{brand.pageTitle} | Home</title>
      </Helmet>
      <SubNav routes={routes} rootTitle="Home" />
      <ViewContainer>
        <ContentContainer>
          <Main title={"Welcome to " + brand.name}>
            <Grid container spacing={2}>
              {accountSettings.can_use_affinity_customers === "1" && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <HomeScreenCard
                    title="Customers"
                    description="Customers and assets management."
                    link="/customers"
                  />
                </Grid>
              )}
              {accountSettings.can_use_affinity_sales === "1" && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <HomeScreenCard
                    title="Sales"
                    description="CPQ tool for quick and accurate quoting and contracting."
                    link="/sales"
                  />
                </Grid>
              )}
              {accountSettings.can_use_affinity_orders === "1" && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <HomeScreenCard
                    title="Orders"
                    description="Complete order management and automated provisioning."
                    link="/orders"
                  />
                </Grid>
              )}
              {accountSettings.can_use_affinity_billing === "1" && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <HomeScreenCard
                    title="Billing"
                    description="Powerful, flexible and scalable billing engine and functionality."
                    link="/billing"
                  />
                </Grid>
              )}
              {accountSettings.can_use_affinity_insight === "1" && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <HomeScreenCard
                    title="Insight"
                    description="Powerful analytics to power data-based business decisions."
                    link="/insight"
                  />
                </Grid>
              )}
              {accountSettings.can_use_affinity_hub === "1" && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <HomeScreenCard
                    title="Hub"
                    description="Suite of applications giving customers access to their accounts 24/7."
                    link="/hub"
                  />
                </Grid>
              )}
              {(accountSettings.can_use_affinity_support === "1" ||
                accountSettings.can_use_affinity_support_new === "1") && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <HomeScreenCard
                    title="Support"
                    description="Intelligent and sufficient support powered by AI and machine learning."
                    link="/support"
                  />
                </Grid>
              )}
            </Grid>
          </Main>
        </ContentContainer>
      </ViewContainer>
    </ProductContainer>
  );
};
