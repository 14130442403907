import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./AppStandalone";
// import * as serviceWorker from "./serviceWorker";
// import { initSentry } from "./initSentry";

console.log(`
Git Revision: ${process.env.REACT_APP_GIT_SHA}
Stage: ${process.env.REACT_APP_STAGE}

`);

// initSentry();
// force deploy

ReactDOM.render(<App />, document.getElementById("root"));
// ReactDOM.render(<App/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
