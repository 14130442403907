import React from "react";
import { HomeScreen } from "./screens/HomeScreen";
import { createRoutingObject } from "./nav/helpers";

export const getRoutes = () =>
  createRoutingObject(
    [
      {
        path: "/",
        title: "Welcome",
        subject: <HomeScreen />
      }
    ],
    "/"
  );
