import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ForgotPassword } from "./auth/ForgotPassword";
import { AffinityNavBar } from "./nav/AffinityNavBar";
import { PlatformMenu } from "./nav/PlatformMenu";
import { ProductLoadingScreen } from "./components/ProductLoadingScreen/ProductLoadingScreen";
import { HomeScreen } from "./screens/HomeScreen";
import { useAuth } from "@akj-dev/affinity-platform";
import { ForbiddenScreen } from "./screens/ForbiddenScreen";
import GlobalSearchScreen from "./screens/GlobalSearch/GlobalSearchScreen";

// @ts-ignore
const SalesApp = lazy(() => import("@akj-dev/affinity-sales"));
const OrderApp = lazy(() => import("@akj-dev/affinity-orders"));
const BillingApp = lazy(() => import("@akj-dev/affinity-billing"));
const InsightApp = lazy(() => import("@akj-dev/affinity-insight"));
const HubApp = lazy(() => import("@akj-dev/affinity-hub"));
const SupportApp = lazy(() => import("@akj-dev/affinity-support"));
const SupportAppNew = lazy(() => import("@akj-dev/affinity-support-new"));
const CustomersApp = lazy(() => import("@akj-dev/affinity-customers"));

type PrivateRouteProps = {
  children: React.ReactNode;
  path: string;
  permission?: string;
};

function PrivateRoute({ children, path, permission }: PrivateRouteProps) {
  return (
    <Route
      path={path}
      render={({ location }) =>
        permission === "1" ? children : <ForbiddenScreen />
      }
    />
  );
}

export const AppRoutes = () => {
  const { accountSettings } = useAuth();
  const { pathname } = useLocation();

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/forgot">
          <ForgotPassword />
        </Route>
        <Route path="/">
          <AffinityNavBar />
          <PlatformMenu />
          <Suspense fallback={<ProductLoadingScreen />}>
            <Switch>
              <Route path="/" exact>
                <HomeScreen />
              </Route>
              <PrivateRoute
                permission={accountSettings.can_use_platform_global_search}
                path="/search"
              >
                <GlobalSearchScreen />
              </PrivateRoute>
              <PrivateRoute
                permission={accountSettings.can_use_affinity_sales}
                path="/sales"
              >
                <SalesApp />
              </PrivateRoute>
              <PrivateRoute
                permission={accountSettings.can_use_affinity_orders}
                path="/orders"
              >
                <OrderApp />
              </PrivateRoute>
              <PrivateRoute
                permission={accountSettings.can_use_affinity_billing}
                path="/billing"
              >
                <BillingApp />
              </PrivateRoute>
              <PrivateRoute
                permission={accountSettings.can_use_affinity_customers}
                path="/customers"
              >
                <CustomersApp />
              </PrivateRoute>
              <PrivateRoute
                permission={accountSettings.can_use_affinity_insight}
                path="/insight"
              >
                <InsightApp />
              </PrivateRoute>
              <PrivateRoute
                permission={accountSettings.can_use_affinity_hub}
                path="/hub"
              >
                <HubApp />
              </PrivateRoute>

              {accountSettings.can_use_affinity_support === "1" ? (
                <PrivateRoute
                  permission={accountSettings.can_use_affinity_support}
                  path="/support"
                >
                  <SupportApp />
                </PrivateRoute>
              ) : accountSettings.can_use_affinity_support_new === "1" ? (
                <PrivateRoute
                  permission={accountSettings.can_use_affinity_support_new}
                  path="/support"
                >
                  <SupportAppNew />
                </PrivateRoute>
              ) : (
                <></>
              )}
            </Switch>
          </Suspense>
        </Route>
      </Switch>
    </QueryParamProvider>
  );
};
