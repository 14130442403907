import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  SelectProps,
  FormHelperText
} from "@material-ui/core";

interface SelectFieldProps extends SelectProps {
  errorText?: string | false;
}

/**
 * MUI Outlined Select Field
 * Note: wrapped mainly because of this issue:
 * https://github.com/mui-org/material-ui/issues/16954
 * where label is displayed incorrectly when it's width isn't computed separately
 *
 * ...actually solved really easily in the end:
 * https://github.com/mui-org/material-ui/issues/23691
 * May as well keep this wrapper anyway, for the time being.
 */
export const SelectField = ({
  label,
  errorText,
  ...otherProps
}: SelectFieldProps) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      disabled={otherProps.disabled}
      error={!!errorText}
    >
      <InputLabel id={`${otherProps.id}-label`}>{label}</InputLabel>
      <Select
        {...otherProps}
        labelId={`${otherProps.id}-label`}
        variant="outlined"
        label={label}
      />
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};
