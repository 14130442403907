export function formattedTimestamp(timestamp: number): string {
  const date = new Date(timestamp * 1000); // convert timestamp to milliseconds
  const dateString = `${date.toLocaleDateString()} ${date.toLocaleTimeString(
    [],
    {
      hour: "2-digit",
      minute: "2-digit"
    }
  )}`;
  return dateString;
}
