import React from "react";
import { Outer } from "../../nav/SubNavComponents";
import { ProductContainer } from "../LayoutContainers";
import { Box, CircularProgress } from "@material-ui/core";
import { SkeletonList } from "./SkeletonList";

export const ProductLoadingScreen = () => {
  return (
    <ProductContainer>
      <Outer>
        <SkeletonList />
      </Outer>
      <Box mx={2} my={1}>
        <CircularProgress />
      </Box>
    </ProductContainer>
  );
};
